import React, { useEffect } from 'react';
import Header from './Header';
import img from '../Pages/img/servicesimg/se1.png'
import img1 from '../Pages/img/servicesimg/se2.png'
import img2 from '../Pages/img/servicesimg/se3.png'
import img3 from '../Pages/img/servicesimg/se4.png'
import img4 from '../Pages/img/servicesimg/se5.png'
import img5 from '../Pages/img/servicesimg/se6.png'
import img6 from '../Pages/img/work.png'
import img7 from '../Pages/img/work1.png'
import Choose from './Choose';
import Testimonial from './Testimonial';
import Contact from './Contact';
import Footer from './Footer';
import { Link } from 'react-router-dom';

function Homepage() {
    // This useEffect hook will run when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);

    const customSectionContent = (
        <>
          <h1>Revolutionize your <br /> busines with <span>innovative</span> <br /> technology solutions.</h1>
        </>
      );

  return (
    <>
        <Header sectionContent={customSectionContent} />
            <section className="services" id='services'>
                <h2>Our Services</h2>
                <p>We develop cutting-edge web design and development, <br /> ensuring your website not only looks stunning but also delivers a seamless user experience.</p>
                
                <div class="parent">
                    <div class="div1" id='div'> 
                        <div className="cont">
                            <img src={img} alt="" />
                            <p>Website backup and recovery involve creating copies of a website's data and files to safeguard against data loss or website downtime. Backups are essential for restoring a website to a previous state in case of...</p>
                            <h4>Website Backup and Recovery</h4>
                        </div>
                    </div>
                    <div class="div2" id='div'> 
                        <div className="cont">
                            <img src={img1} alt="" />
                            <p>Web development and design encompass the creation and maintenance of websites. Development...</p>
                            <h4>Website Design and Development</h4>
                        </div>
                    </div>
                    <div class="div3" id='div'> 
                        <div className="cont">
                            <img src={img2} alt="" />
                            <p>Search Engine Optimization (SEO) is the practice of optimizing online content to improve its visibility and ranking on search engine results pages (SERPs). The goal is to increase organic (non-paid) traffic to a website by optimizing...</p>
                            <h4>Search Engine Optimization(SEO)</h4>
                        </div>
                    </div>
                    <div class="div4" id='div'> 
                        <img src={img3} alt="" />
                        <div className="conts">
                            <h4>Ongoing Website Maintenance and Support</h4>
                            <p>Reach out to us today and discover how our Ongoing Website Maintenance and Support services can elevate your online presence and unlock new opportunities for your business.</p>
                            <a href="">Request An Estimate <span class="material-symbols-outlined">arrow_circle_up</span></a>
                        </div>
                    </div>
                    <div class="div5" id='div'>   
                        <div className="cont">                        
                            <img src={img4} alt="" />
                            <p>Hosting: The process of storing and managing website files on a server, making the site accessible on the internet. <br /> Domain Registration: The acquisition of a unique web address (URL) for a website, enabling users to access it via a recognizable name</p>
                            <h4>Hosting and Domain Registration</h4>
                        </div>   
                    </div>
                    <div class="div6" id='div'>
                        <div className="cont">                        
                            <img src={img5} alt="" />
                            <p>Branding is the process of creating a distinct ident...</p>
                            <h4>Branding and Marketing</h4>
                        </div> 
                    </div>
                </div>
            </section>
        <Choose/>

        <section className="works">
            <div className="title">
                <h3>Check Out Some Of Our Works</h3>
                <Link to="/portfolio">View All</Link>
            </div>
            <div className="img">
                <img src={img6} alt="" />
                <img src={img7} alt="" />
            </div>
        </section>

        <Testimonial/>

        <Contact/>
        <Footer/>
    </>
  ); 
}

export default Homepage;
