// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'; // Import Link
import './App.css';
import Homepage from './Pages/Homepage';
import AboutUs from './Pages/AboutUs';
import Portfolio from './Pages/Portfolio';
import ProjectDetail from './Pages/ProjectDetail';
import { projects } from './Pages/projects';
import Services from './Pages/Services';
import ServiceDetail from './Pages/ServiceDetail';
import HireUs from './Pages/HireUs';
import ComingSoon from './ComingSoon';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          {/* <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/services" element={<Services />} />
          <Route path="/hire" element={<HireUs />} />
          <Route path="/service/:id" element={<ServiceDetail />} />
          <Route
            path="/project/:id"
            element={<ProjectDetail projects={projects} />}
          /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
