import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import emailjs from 'emailjs-com';

// Initialize emailjs with your user ID
emailjs.init('S6475OaJ4B6oqcrzy');

const Contact = () => {
  // This useEffect hook will run when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  // Define custom styles
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#fff' : '#118FD6',
      color: state.isSelected ? '#118FD6' : 'white',
    }),
    singleValue: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      color: 'white',
    }),
    control: (provided) => ({
      ...provided,
      padding: '0px 10px',
      borderRadius: '10px',
      border: '1px solid #118FD6',
      background: 'transparent',
      fontSize: '13px',
    }),
  };


  const [selectedCategory, setSelectedCategory] = useState(null);


  const [formData, setFormData] = useState({
    from_name: '',
    message: '',
  });


  const handleFormSubmit = (event) => {
    event.preventDefault();


    if (!selectedCategory) {
      alert('Please select a category');
      return;
    }


    const serviceID = 'service_tf0re7o';

    const templateID = 'template_1fthflm'; 


    const message = `Selected Category: ${selectedCategory.label}\n\n${formData.message}`;

    setFormData({ ...formData, message });


    emailjs
      .send(serviceID, templateID, formData)
      .then((response) => {
        console.log('Email sent successfully:', response);
        alert('Email sent successfully!');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        alert('Error sending email. Please try again later.');
      });
  };

  return (
    <div>
      <section className="contact">
        <div className="contact_banner">
          <h1>Have a Project <br /> Idea?</h1>
          <p>At Incognito, with an unwavering focus on creativity and innovation, we design with purpose, leaving a lasting impact on your audience. Partner with us to unlock the true potential of design and create extraordinary journeys that make people say, "Wow."</p>
        </div>

        <div className="contact_body">
          <div className="col_left">
            <h3>Contact Us</h3>
            <p>Create a Website that Reflects Your Business: B2B, B2C, C2C and C2B Website Design and Development Services. Building a Strong Online Presence for Your Business. Let us do the Job.</p>

            <div className="col one">
              <label htmlFor="category">Select Category:</label>
              <ReactSelect
                options={[
                  { value: 'web', label: 'Web Design and Development' },
                  { value: 'app', label: 'App Design and Development' },
                  { value: 'software', label: 'Software development' },
                  { value: 'uiux', label: 'UI/UX Designs' },
                  { value: 'marketing', label: 'Marketing & Development' },
                ]}
                isSearchable={false}
                styles={customStyles}
                onChange={(selectedOption) => setSelectedCategory(selectedOption)}
              />
            </div>

            <div className="col two">
              <label htmlFor="fullName">Full Name:</label>
              <input
                type="text"
                name="from_name"
                id="from_name"
                placeholder="John Doe"
                onChange={(e) => setFormData({ ...formData, from_name: e.target.value })}
              />
            </div>

            <div className="col three">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                name="message"
                id="message"
                placeholder="@gmail.com"
                style={{ textAlign: 'right', direction: 'rtl' }}
                onChange={(e) => {
                  if (e.target.value) {
                    e.target.style.textAlign = 'left';
                    e.target.style.direction = 'ltr';
                  } else {
                    e.target.style.textAlign = 'right';
                    e.target.style.direction = 'rtl';
                  }
                  setFormData({ ...formData, message: e.target.value });
                }}
              />
            </div>

            <button className="custom-btn btn-12" onClick={handleFormSubmit}>
              <span>Click!</span>
              <span>Contact Us</span>
            </button>
          </div>

          <div className="line"></div>

          <div className="col_right">
            <h3>Contact Support</h3>
            <div className="col ones">
              <h4>Contact Support</h4>
              <p>At Incognito Tech-Solutions Limited, our contact support is your direct link to hassle-free tech assistance. Our dedicated team is here 24/7 to address your queries, providing swift and reliable solutions. Your satisfaction is our priority, and we're committed to ensuring your tech experience is seamless. Reach out anytime, and let us make technology work for you. Welcome to a support service that's as dynamic as your tech needs!</p>
              <button className="custom-btns btn-3"><span>Call Us: </span></button> <br /><br /> <span style={{fontWeight: "bolder"}}>0807 311 9791</span> 
            </div>
            <div className="col twos">
              <h4>Email Support</h4>
              <p>"Experience seamless support with Incognito Tech-Solutions Limited! Our dedicated email support is here to swiftly address your tech queries. Trust us to keep your digital experience hassle-free."</p>
              <button className="custom-btns btn-3"><span>Send Email: it.incognitotechs@gmail.com</span></button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
