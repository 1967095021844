import React, { useState, useEffect } from 'react';
import video from './Pages/back.mp4';
import img1 from './Pages/img/loogo.png';
import './index.css';
import img from "./new.png";
import twitter from "./twitter.png";
import instagram from "./instagram.png";
import back from "./back.jpeg";

const ComingSoon = () => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const targetDate = new Date('2024-09-09T00:00:00');
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [time, setTime] = useState(calculateTimeLeft());
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageClass, setMessageClass] = useState('');
  const [loading, setLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const timeFunction = setInterval(() => {
      setTime(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timeFunction);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formatTime = (value) => (value < 10 ? `0${value}` : value);

  const handleNotifyMe = async () => {
    if (!email) {
      setMessage('Please enter a valid email.');
      setMessageClass('');
      return;
    }

    setLoading(true);

    try {
      const response = await fetch('https://incogbackend.onrender.com/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage('Thank you for subscribing!');
        setMessageClass('success-message');
        setEmail(''); // Clear input field
      } else {
        setMessage('Something went wrong. Please try again.');
        setMessageClass('');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again later.');
      setMessageClass('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="type">
      <div className='head'>
        <img src={img1} alt="logo" className="logo" />
        <div className="header">
          <div className="header-content">
            <section className="content">
              <header>Launching soon</header>
              <p className='pep'>
              Technology doesn't have to be intimidating. At Incognito Tech-
              Solutions Limited, we believe innovation should empower you, your business, and your people. Our expert services - including Web Design & Development, App Development, Domain & Hosting Solutions, and SEO Analysis - are designed to make tech work for you. Let's innovate together!
              </p>
              
              {/* <div className="time-content">
                <div className="time days">
                  <span className="number">{formatTime(time.days)}</span>
                  <span className="text">days</span>
                </div>
                <div className="time hours">
                  <span className="number">{formatTime(time.hours)}</span>
                  <span className="text">hours</span>
                </div>
                <div className="time minutes">
                  <span className="number">{formatTime(time.minutes)}</span>
                  <span className="text">minutes</span>
                </div>
                <div className="time seconds">
                  <span className="number">{formatTime(time.seconds)}</span>
                  <span className="text">seconds</span>
                </div>
              </div> */}
              
              <div className="email-content">
                {windowWidth >= 1050 && (
                <p>
                  <span className="subscribe-text">Subscribe</span> now to get notified when we launch
                </p>
                )}
                
                <div className="input-box">
                  <input
                    type="email"
                    placeholder="Enter your email..."
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button onClick={handleNotifyMe} disabled={loading}>
                    {loading ? <div className="loader"></div> : 'Subscribe'}
                  </button>
                </div>

                {message && <p className={messageClass}>{message}</p>}
                <p style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <span className="subscribe-text">SOCIALS</span> 
                    <a href="https://www.instagram.com/incognito_techs/?hl=en" target='_blank' style={{margin: "0 2%"}}>
                      <img src={instagram} alt="" />  
                    </a>  
                    <a href="https://x.com/incognito_techs" target='_blank' style={{margin: "0 2%"}}>
                      <img src={twitter} alt="" />  
                    </a> <span style={{padding: "2% 0"}}>@incognito_techs</span> 
                </p>
              </div>
            </section>
            <section className="img">
              <img src={img} alt="" />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
